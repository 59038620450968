import { Component, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-adsense',
  template: `
    <div class="ad-container">
      <ins class="adsbygoogle"
           [style.display]="display"
           [style.width.px]="width"
           [style.height.px]="height"
           [attr.data-ad-client]="adClient"
           [attr.data-ad-slot]="adSlot"
           [attr.data-ad-format]="adFormat"
           [attr.data-full-width-responsive]="fullWidthResponsive">
      </ins>
    </div>
  `,
  styleUrls: ['./adSense.component.css'],
  standalone: true
})
export class AdsenseComponent implements AfterViewInit {
  @Input() adClient: string = '5571142220';
  @Input() adSlot: string = 'XXXXXXXXXX';
  @Input() adFormat: string = 'auto';
  @Input() fullWidthResponsive: boolean = true;
  @Input() display: string = 'block';
  @Input() width: number = 728;
  @Input() height: number = 90;

  ngAfterViewInit() {
    try {
      ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
    } catch (e) {
      console.error('Ad error:', e);
    }
  }
}
