import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer',
  template: `
    <footer class="footer">
      <div class="footer-content">
        <p>&copy; {{ currentYear }} Casa Faletto. All rights reserved.</p>
        <p>Síguenos en:
          <a href="https://www.facebook.com/casahuespedesfaletto" target="_blank"><i class="fab fa-facebook"></i> Facebook</a> |
          <a href="https://www.instagram.com/casafaletto/" target="_blank"><i class="fab fa-instagram"></i> Instagram</a> |
          <a href="https://www.airbnb.com" target="_blank"><i class="fab fa-airbnb"></i> Airbnb</a> |
          <a href="https://www.booking.com/hotel/co/casa-de-huespedes-faletto.es.html" target="_blank"><i class="fab fa-booking"></i> Booking</a> |
          <a href="https://twitter.com" target="_blank"><i class="fab fa-twitter"></i> Twitter</a>
        </p>
      </div>
    </footer>
  `,
  styleUrls: ['./footer.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class FooterComponent {
  currentYear: number = new Date().getFullYear();
}
