import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-whatsapp-button',
  template: `
    <div class="whatsapp-container">
      <div class="whatsapp-image" (click)="openChat(phone1)">
        <i class="fab fa-whatsapp"></i>
        <span>Línea 1</span>
      </div>
      <div class="whatsapp-image" (click)="openChat(phone2)">
        <i class="fab fa-whatsapp"></i>
        <span>Línea 2</span>
      </div>
    </div>
  `,
  styleUrls: ['./whatsapp-button.component.css'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappButtonComponent {
  @Input() phone1: string = '+573005640090';
  @Input() phone2: string = '+573192394232';
  @Input() message: string = 'Hola, me gustaría reservar un lugar. ¡Quiero más información!';

  private createWhatsAppUrl(phone: string): string {
    return `https://wa.me/${phone}?text=${encodeURIComponent(this.message)}`;
  }

  openChat(phone: string): void {
    window.open(this.createWhatsAppUrl(phone), '_blank');
  }
}
