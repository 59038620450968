import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AdsenseComponent } from './adsense/adsense.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    HomeComponent,
    FooterComponent,
    AdsenseComponent
  ]
})
export class AppComponent {
  title = 'Casa Faletto';
}
