<div class="app-container">
  <main>
    <header class="brand-name">
      <img class="brand-logo centered-image" src="/assets/logo.svg" alt="logo" aria-hidden="true">
    </header>
    <section class="content">
      <app-home></app-home>
      <app-adsense
        adClient="your-ad-client"
        adSlot="your-ad-slot"
        [fullWidthResponsive]="true">
      </app-adsense>
    </section>
  </main>
</div>
