import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../footer/footer.component';
import { WhatsappButtonComponent } from '../whatsapp/whatsapp-button.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    WhatsappButtonComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <section class="center-content">
      <h1>{{ title }}</h1>
      <span class="business-description">{{ description }}</span>
      <span class="business-availability">{{ availability }}</span>

      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
        <ol class="carousel-indicators">
          <li *ngFor="let image of images; let i = index; trackBy: trackByFn" 
              [attr.data-bs-slide-to]="i"
              [class.active]="i === 0"></li>
        </ol>
        <div class="carousel-inner">
          <div *ngFor="let image of images; let i = index; trackBy: trackByFn" 
               class="carousel-item" 
               [class.active]="i === 0" 
               [attr.data-bs-interval]="5000">
            <img class="d-block w-100" 
                 [src]="image.url" 
                 [alt]="image.caption"
                 loading="lazy">
            <div class="carousel-caption d-none d-md-block">
              <h5>{{ image.caption }}</h5>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>

      <div class="fGZQDc">
        <div class="kCmrbf"><h3 class="Gou21b">Contacto</h3>
          <div data-field="phone"><a class="PDvGL q8cvFf" href="tel:+57-300-5640090" data-tracking-element-type="3"
                                     jslog="56037; track:impression,click" itemprop="telephone" dir="ltr">Llamar ahora</a>
            <br>
            <div>+573005640090</div>
            <div>+573192394232</div>
          </div>
        </div>
        <div class="kCmrbf"><h3 class="Gou21b">Dirección</h3>
          <div data-field="address"><a class="PDvGL q8cvFf"
                                       href="https://www.google.com/maps/dir//Casa+Faletto/data=!4m8!4m7!1m0!1m5!1m1!1s0x8e41d7138cb42839:0xbf019e12c04a4f59!2m2!1d-73.5295177!2d5.6342215"
                                       target="_blank" data-tracking-element-type="6" jslog="56039; track:impression,click">Cómo llegar</a>
            <address class="qhkvMe " itemprop="address">
              <div>Barrio Santander</div>
              <div>Calle 7 #12b-103</div>
              <div>Villa de Leyva, Boyacá</div>
              <div>Colombia</div>
            </address>
          </div>
        </div>
        <div class="kCmrbf"><h3 class="Gou21b">Horario de atención</h3>
          <div class="operationHours">
            <table itemprop="openingHours">
              <tbody>
              <tr>
                <th class="x2TOCf">Lunes:</th>
                <td class="o0m3Qb"><span class="WF8WNe">Abierto las 24 horas</span></td>
              </tr>
              <tr>
                <th class="x2TOCf">Martes:</th>
                <td class="o0m3Qb"><span class="WF8WNe">Abierto las 24 horas</span></td>
              </tr>
              <tr>
                <th class="x2TOCf">Miércoles:</th>
                <td class="o0m3Qb"><span class="WF8WNe">Abierto las 24 horas</span></td>
              </tr>
              <tr>
                <th class="x2TOCf">Jueves:</th>
                <td class="o0m3Qb"><span class="WF8WNe">Abierto las 24 horas</span></td>
              </tr>
              <tr>
                <th class="x2TOCf">Viernes:</th>
                <td class="o0m3Qb"><span class="WF8WNe">Abierto las 24 horas</span></td>
              </tr>
              <tr>
                <th class="x2TOCf">Sábado:</th>
                <td class="o0m3Qb"><span class="WF8WNe">Abierto las 24 horas</span></td>
              </tr>
              <tr>
                <th class="x2TOCf">Domingo:</th>
                <td class="o0m3Qb"><span class="WF8WNe">Abierto las 24 horas</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="kCmrbf">
          <h3 class="Gou21b">Precios</h3>
          <div>
            <ul>
              <li>Habitación Estándar: $80.000 COP por noche</li>
              <li>Habitación Doble: $160.000 COP por noche</li>
            </ul>
          </div>
        </div>
      </div>

      <app-whatsapp-button></app-whatsapp-button>
      <app-footer></app-footer>
    </section>
  `,
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  private static readonly BUSINESS_INFO = {
    title: 'Casa Faletto',
    description: 'Hotel en Villa de Leyva',
    availability: 'Abierto las 24 horas'
  };

  private static readonly IMAGES = [
    {url: 'assets/faletto_1.jpeg', caption: 'Entrada'},
    {url: 'assets/faletto_2.jpeg', caption: 'Parqueadero'},
    {url: 'assets/faletto_3.jpeg', caption: 'Habitación Cupcone'},
    {url: 'assets/faletto_4.jpeg', caption: 'Habitación Buccellato'},
    {url: 'assets/faletto_5.jpeg', caption: 'Habitación Dacquoise'},
    {url: 'assets/faletto_6.jpeg', caption: 'Habitación Amandine'},
  ];

  readonly title = HomeComponent.BUSINESS_INFO.title;
  readonly description = HomeComponent.BUSINESS_INFO.description;
  readonly availability = HomeComponent.BUSINESS_INFO.availability;
  readonly images = HomeComponent.IMAGES;

  trackByFn(index: number): number {
    return index;
  }
}
